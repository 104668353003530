<template>
  <PdfPreviewTemplate
    :isAvailable="isAvailable"
    :pdfBlob="pdfBlob"
    :errorMessage="errorMessage"
  />
</template>
<script>
import { getApplicationFormMaster } from '@/apis/agency/applicationForms';
import PdfPreviewTemplate from '@/pages/agency/templates/PdfPreviewTemplate';
import { mapActions } from 'vuex';

export default {
  name: 'ApplicationFormMasterPreview',
  components: {
    PdfPreviewTemplate,
  },

  data() {
    return {
      // 有効であるかどうか
      isAvailable: true,
      // PDFファイル
      pdfBlob: null,

      // エラーメッセージ
      errorMessage: this.$t('description.applicationFormMasterNotFound'),
    };
  },
  async mounted() {
    this.setLoading(true);
    // 申込書の取得
    const blob = await getApplicationFormMaster(
      this.$route.params['applicationFormId']
    ).catch(() => {
      this.$set(this, 'isAvailable', false);
    });
    this.setLoading(false);
    // 申込書が取得できなかった場合、以降の処理を中止する
    if (!blob) return;
    this.$set(this, 'pdfBlob', blob);
  },
  methods: {
    ...mapActions('ui', ['setLoading']),
  },
};
</script>
